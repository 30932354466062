*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.page-container {
  position: relative;
  display: grid;
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
}

.main-container {
  height: 100%;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

#svg path {
  fill: #000;
}

#svg circle {
  fill: #000;
}

/* Riskanalys svg specifics */
#svg #border {
  fill: #becbf9;
}

/* Information svg specifics */
#svg #infobody {
  fill: #000;
}

/* Hover */
.card_fakeNews:hover rect,
.card_choose:hover rect,
.card_decide:hover rect,
.card_analsysis:hover rect,
.card_tips:hover rect,
.card_personal:hover rect {
  fill: #000;
}
.card_fakeNews:hover #svg path,
.card_choose:hover #svg path,
.card_decide:hover #svg path,
.card_analsysis:hover #svg path,
.card_tips:hover #svg path,
.card_personal:hover #svg path {
  fill: #fff;
}
.card_decide:hover #svg circle,
.card_analsysis:hover #svg circle,
.card_personal:hover #svg circle {
  fill: #fff;
}

.card_analsysis:hover #svg #border {
  fill: #000;
}
.card_personal:hover #svg #infobody {
  fill: #fff;
}

@media screen and (max-width: 768px) {
  #svg {
    width: 220px;
    height: 220px;
  }
}

@media screen and (max-width: 500px) {
  #svg {
    width: 190px;
    height: 190px;
  }
}
